@import '../../../assets/scss/styles/media-queries.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
button.MuiButton-containedPrimar {
    background-color: transparent !important;
    box-shadow: none !important;
}

button.MuiButton-containedPrimary {
    color: #ffffff !important;
    font-family: 'Roboto', sans-serif;
    background-color: transparent !important;
    font-size: 18px !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
    margin-top: -3px !important;
    padding: 0 !important;
}

button.MuiButton-contained {
    box-shadow: none !important;
}

.menu {
    display: flex;
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        margin-top: 13px;
        @include respond(lower991) {
            li:first-child {
                display: none;
            }
            li:nth-child(3) {}
        }
        li {
            a {
                padding: 0 18px;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                color: rgba(0, 0, 0, 0.5);
                font-size: 17px;
                font-weight: bold;
                font-family: 'Roboto', sans-serif;
            }
            a:hover {
                text-decoration: none;
                color: #ffc900;
            }
        }
        li:last-child {
            a {
                border-right: none;
            }
        }
        li.active {
            a {
                color: #ffc900;
            }
        }
    }
    .profile {
        margin-top: 13px;
        font-size: 17px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        >svg {
            color: #ffc900;
            line-height: 25px;
            height: 25px;
            margin-right: 5px;
        }
        >a {
            line-height: 20px;
            padding-left: 6px;
            padding-right: 6px;
            color: rgba(0, 0, 0, 0.4);
            &:hover {
                color: #ffc900;
                text-decoration: none;
            }
        }
    }
    .wallet-button {
        float: left;
        margin-top: 5px;
        margin-left: 8px;
        height: 36px;
        z-index: 0;
        position: relative;
        background: #f7ca18;
        overflow: hidden;
        @include respond(lower600) {
            overflow: visible;
        }
        a {
            float: left;
            color: #fff;
            padding: 5px 28px 5px 46px;
            font-weight: bold;
            background: #f7ca18;
            font-size: 18px;
            position: relative;
            @include respond(lower600) {
                padding: 5px 20px;
            }
            &:hover {
                text-decoration: none;
            }
            svg {
                position: absolute;
                top: 8px;
                left: 20px;
                @include respond(lower600) {
                    display: none;
                }
            }
        }
        &:after {
            content: '';
            top: 0;
            left: 0;
            transform: translateX(100%);
            width: 100%;
            height: 100px;
            position: absolute;
            z-index: 1;
            animation: slide 2s infinite 4s;
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
            background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0)));
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
            background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
            background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
            @include respond(lower600) {
                display: none;
            }
        }
        /* animation */
        @keyframes slide {
            0% {
                transform: scale(1.5) rotateZ(-10deg) translateX(-100%);
            }
            100% {
                transform: scale(1.5) rotateZ(-10deg) translateX(100%);
            }
        }
    }
}

.mobile-menu-icons {
    display: none;
    border: none;
    outline: none;
    height: 20px;
    margin-top: 12px;
    background: none;
    font-size: 24px;
    font-weight: 100;
    @include respond(lower768) {
        display: block;
        margin-left: 20px;
        position: relative;
        z-index: 40;
    }
    svg {
        fill: black;
    }
}

.mobile-menu-icons.mobile-menu-x {
    svg {
        fill: white;
        position: fixed;
        right: 20px;
        top: 20px;
    }
}

ul.nav-links {
    @include respond(lower768) {
        display: none;
    }
}

ul.nav-links-mobile {
    @include respond(lower768) {
        display: flex;
        flex-direction: column;
        background: #242328;
        position: fixed;
        top: -13px;
        left: 0;
        width: 100vw;
        transition: all 0.5s ease-out;
        z-index: 30;
        padding-top: 30px;
        padding-bottom: 30px;
        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            transition: all 0.5s ease;
            a {
                border-right: 0;
                color: #fff;
                font-weight: normal;
            }
        }
        li:last-child {
            border: none;
        }
    }
}