 @import '../../../assets/scss/styles/media-queries.scss';
 @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
 /*TopBar*/
 
 #topbar {
     padding: 12px 0px;
     /*display: table;*/
     width: 100%;
     position: relative;
 }
 
 #topbar::before {
     background-color: #242328;
     bottom: 0;
     content: '';
     left: 0;
     position: absolute;
     right: 67%;
     top: 0;
     @include respond(lower991) {
         right: 70%;
     }
     @include respond(lower768) {
         right: 50%;
     }
     @include respond(lower600) {
         right: 47%;
     }
     @include respond(lower500) {
         right: 70%;
     }
     @include respond(more1800) {
         right: 60%;
     }
     @include respond(more3000) {
         right: 57%;
     }
 }
 
 #logo>a {
     float: left;
     font-family: 'Roboto', sans-serif;
     font-weight: normal;
     font-size: 30px;
     color: #ffc900;
     text-transform: uppercase;
     text-decoration: none;
     outline: 0;
     margin-right: 20px;
     @include respond(lower500) {
         margin-right: 10px;
     }
 }
 
 #logo {
     float: left;
     @include respond(lower500) {
         width: 30% !important;
     }
 }
 
 .topbar-address {
     text-align: right;
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
 }
 
 div.topbar-address {
     @include respond(lower500) {
         width: 70% !important;
     }
 }
 
 .topbar-address>a {
     font-family: 'Roboto', sans-serif;
     font-weight: normal;
     font-size: 15px;
     color: #757575;
     display: inline-block;
     margin-top: 12px;
     margin-right: 16px;
 }
 
 .topbar-address>a:hover,
 .topbar-address>a:focus {
     color: #ffc900;
     outline: none;
     text-decoration: none;
 }
 
 .topbar-address>a i {
     color: #ffc900;
     position: relative;
     top: 4px;
     margin-right: 8px;
 }
 
 .header-social-icons {
     border-left: 1px solid #919191;
     margin-top: 8px;
     display: flex;
     flex-direction: row;
     @include respond(lower991) {
         display: none;
     }
     @include respond(lower768) {
         display: flex;
     }
     @include respond(lower500) {
         display: none;
     }
     a {
         padding-left: 15px;
         @include respond(lower500) {
             padding-left: 5px;
         }
         svg {
             float: left;
             padding-top: 4px;
             padding-bottom: 4px;
             font-size: 30px;
             fill: rgba(255, 255, 255, 0.8);
         }
     }
     a:hover {
         svg {
             fill: #ffc900;
         }
     }
 }
 /* Banner */
 
 .proba {
     display: flex;
     align-items: center;
     padding-bottom: 100px;
     @include respond(lower768) {
         flex-direction: column;
         padding-left: 40px;
         padding-right: 40px;
     }
 }
 
 .col-pull-middle {
     display: table-cell;
     float: none;
     vertical-align: middle;
 }
 
 div.module.parallax {
     height: auto;
     background-position: 50% 50%;
     background-repeat: no-repeat;
     background-attachment: fixed;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
 }
 
 div.module.parallax-1 {
     background-image: url('./images/banner-bg.jpg');
     padding: 70px 0 125px 0;
 }
 
 .banner-text {
     h1 {
         font-family: 'Roboto', sans-serif;
         font-weight: bold;
         color: #fff;
         margin: 0px;
         font-size: 60px;
         text-shadow: 2px 1px 5px rgba(0, 0, 0, 1);
         margin-bottom: 20px;
         margin-top: 40px;
         span {
             color: #ffc900;
         }
     }
     p {
         color: #fff;
         text-transform: none;
         font-size: 18px;
         font-family: 'Roboto', sans-serif;
     }
     h3 {
         font-family: 'Roboto', sans-serif;
         font-weight: bold;
         color: #fff;
         margin: 0px;
         margin-bottom: 20px;
         font-size: 28px;
         text-shadow: 2px 1px 5px rgba(0, 0, 0, 1);
     }
     a.yellow-button {
         @include respond(lower768) {
             margin-top: 20px;
             margin-bottom: 60px;
         }
     }
 }
 
 .form-container {
     background: rgba(36, 35, 40, 0.94);
     border-radius: 5px;
     padding: 15px;
     overflow: hidden;
     border-radius: 25px 0px 25px 0px;
     border-top: 5px solid #f7ca18;
     box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
 }
 
 .form-head {
     /*
      border-bottom: 2px solid #7ccb0d;
      border-top: 2px solid #7ccb0d;
  */
     hr {
         width: 99px;
         height: 1px;
         margin: 15px auto 20px;
         border: none;
         background: #ffc900;
     }
     h3 {
         color: #ffc900;
         font-family: 'Roboto', sans-serif;
         font-weight: 400;
         font-size: 42px;
         margin-top: 0;
         margin-bottom: 0;
         font-weight: bold;
         padding: 15px 25px 0px;
         @include respond(lower1200) {
             font-size: 32px;
         }
     }
     p {
         font-family: 'Roboto';
         font-weight: normal;
         font-size: 14px;
         padding: 0px 3px;
         color: #d9d9d9;
         line-height: 1.8em;
         margin-top: 10px;
         margin-bottom: 10px;
         b {
             font-size: 20px;
             color: #fff;
             @include respond(lower991) {
                 font-size: 18px;
             }
         }
     }
 }
 
 #signUp-form {
     padding: 0px 25px;
     .form-group {
         margin-bottom: 20px;
     }
     .form-control {
         font-family: 'Roboto';
         height: 40px;
         background: transparent;
         border-top: none;
         border-left: 0;
         border-right: 0;
         border-bottom: 1px solid rgba(146, 145, 146, 0.5);
         border-radius: 0;
         color: #c3c3c3;
         width: 100%;
     }
     .form-control:focus {
         box-shadow: none;
         border-color: rgba(255, 201, 0, 0.6);
         border-top: none;
         border-left: none;
         border-right: none;
     }
     .form-label {
         display: inline-block;
         color: #c3c3c3;
         font-family: 'Roboto';
         font-weight: lighter;
         margin-bottom: 0;
         margin-top: 20px;
         font-size: 15px;
     }
     .btn-submit {
         background: rgba(0, 0, 0, 0);
         font-family: 'Roboto';
         font-weight: 700;
         font-size: 22px;
         margin-top: 15px;
         display: inline-block;
         color: #ffc900;
         border-color: #ffc900;
         padding: 12px 0px;
         border-radius: 0px;
         overflow: hidden;
         position: relative;
         border-width: 2px;
         transition: all 0.3s ease;
         width: 80%;
         margin-bottom: 20px;
         @include respond(lower768) {
             width: 200px;
         }
     }
     .btn-submit:hover {
         background-color: #ffc900;
         color: #000;
     }
     .btn-submit::after {
         content: '';
         position: absolute;
         top: -50%;
         right: -50%;
         bottom: -50%;
         left: -70%;
         background: linear-gradient( to bottom, rgba(229, 172, 142, 0), rgba(255, 255, 255, 0.3) 50%, rgba(229, 172, 142, 0));
         transform: rotateZ(60deg) translate(-5em, 7.5em);
     }
     .btn-submit.btn-facebook {
         background: #2e3192;
         font-weight: 500;
         color: #fff;
         border-color: #2e3192;
         margin-top: 0px;
         margin-bottom: 15px;
     }
     .btn-submit:hover::after,
     .btn-submit:focus::after {
         animation: shine 1s forwards;
         right: -90%;
     }
     .or span {
         display: inline-block;
         width: 100%;
         position: relative;
         font-size: 24px;
         font-weight: 700;
         margin: 15px 0px;
         color: #fff;
     }
     .or span:before,
     .or span:after {
         content: '';
         width: 100%;
         height: 1px;
         background: #5c5b5d;
         position: absolute;
         top: 50%;
         margin-top: -0.5px;
     }
     .or span:before,
     .or span:after {
         content: '';
         width: 100%;
         height: 1px;
         background: #5c5b5d;
         position: absolute;
         top: 50%;
         margin-top: -0.5px;
     }
     .or span:before {
         left: -60%;
     }
     .or span:after {
         right: -60%;
     }
     @keyframes shine {
         100% {
             transform: rotateZ(60deg) translate(1em, -9em);
         }
     }
     /*@-moz-keyframes shine {
   100% {
     transform: rotateZ(60deg) translate(1em, -9em);
   }
 }
 @-webkit-keyframes shine {
   100% {
     transform: rotateZ(60deg) translate(1em, -9em);
   }
 }*/
 }
 
 @keyframes shine {
     100% {
         transform: rotateZ(60deg) translate(1em, -9em);
     }
 }
 /*@-moz-keyframes shine {
    100% {
      transform: rotateZ(60deg) translate(1em, -9em);
    }
  }
  @-webkit-keyframes shine {
    100% {
      transform: rotateZ(60deg) translate(1em, -9em);
    }
  }*/
 /* form-submision */
 
 .formOverlay:before {
     content: '\f110';
     font-family: fontawesome;
     -webkit-animation: fa-spin 1s infinite steps(8);
     animation: fa-spin 1s infinite steps(8);
     color: #ffc900;
     position: absolute;
     top: 50%;
     left: 50%;
     margin-left: -5%;
     font-size: 56px;
     margin-top: -34px;
 }
 
 .formOverlay {
     display: block;
     height: 100%;
     left: 0;
     position: absolute;
     top: 0;
     width: 100%;
     z-index: 9999;
 }
 
 .alert .message-icon {
     margin-right: 10px;
     width: 30px;
     height: 30px;
     text-align: center;
     border: 1px solid #9f9f9f;
     border-radius: 50%;
     line-height: 30px;
 }
 
 .form-control.error {
     border-color: rgba(166, 0, 0, 0.4) !important;
     box-shadow: 0 1px 1px rgba(56, 165, 74, 0.1) inset, 0 0 4px rgba(166, 0, 0, 0.4) !important;
     -webkit-box-shadow: 0 1px 1px rgba(56, 165, 74, 0.1) inset, 0 0 4px rgba(166, 0, 0, 0.4) !important;
     -moz-box-shadow: 0 1px 1px rgba(56, 165, 74, 0.1) inset, 0 0 4px rgba(166, 0, 0, 0.4) !important;
 }
 
 label.error {
     display: none !important;
 }
 /* form-submision */
 
 .header-home {
     .simplepage-header {
         display: none;
     }
 }
 
 .header-page {
     #banner {
         display: none;
     }
     .simplepage-header {
         width: 100%;
         height: 280px;
         background-image: url('./images/banner-bg2.jpg');
         background-size: cover;
         margin: 0;
     }
 }
 
 .row-banner2 {
     height: 280px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     h1 {
         color: #fff;
         text-transform: capitalize;
         margin-bottom: 20px;
     }
     span {
         display: flex;
         align-items: center;
         justify-content: center;
         a {
             text-transform: capitalize;
             color: #ffc909;
             padding-left: 15px;
             padding-right: 15px;
         }
         a:last-child {
             color: #ffffff;
             &:hover {
                 text-decoration: none;
             }
         }
         svg {
             fill: #ffc909;
             height: 20px;
         }
     }
 }
 
 .yellow-button {
     float: left;
     background: #ffc900;
     color: #fff;
     text-decoration: none;
     font-weight: bold;
     font-size: 17px;
     margin-top: 60px;
     padding: 10px 20px;
     border: 1px solid #ffc900;
     svg {
         margin-right: 4px;
     }
     &:hover {
         color: #ffc900;
         background: transparent;
         text-decoration: none;
     }
 }
 
 .header.header-gallery {
     #banner {
         display: none;
     }
     #banner2 {
         display: none;
     }
     #topbar {
         border-bottom: 3px solid #242328;
     }
 }