@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #35383e;
}

a,
button,
.btn {
    transition: all 0.3s ease 0s;
    outline: none;
    text-decoration: none;
}

a:hover,
a:focus,
a:active,
button:hover,
button:active,
button:focus,
.btn:hover,
.btn:active,
.btn:focus {
    text-decoration: none;
    outline: none;
}

.wow {
    visibility: hidden;
}

.text-uppercase {
    text-transform: uppercase;
}

.overflow-hidden {
    overflow: hidden;
}

p {
    line-height: 1.6em;
}

img {
    max-width: 100%;
}

.for-col-pull-down {
    display: table;
    /*width: 100%;*/
}

.col-pull-down {
    display: table-cell;
    vertical-align: bottom;
    float: none;
}

.nopadding {
    padding-left: 0;
    padding-right: 0;
}

.section-heading {
    text-align: center;
    h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 48px;
        color: #ffc900;
        margin: 0px;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        margin-bottom: 40px;
    }
    h2::before {
        background-color: #5f5f5f;
        bottom: -20px;
        content: '';
        height: 2px;
        left: 50%;
        margin-left: -36px;
        position: absolute;
        width: 72px;
    }
    p {
        color: #5f5f5f;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        line-height: 1.7em;
        margin: 20px 0 35px;
        padding: 0 150px;
    }
}

.seperator,
.lite-seperator {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.seperator {
    width: 73px;
    height: 25px;
    /* background-image: url(../images/seperator.png);*/
}

.lite-seperator {
    width: 60px;
    height: 20px;
    /* background-image: url(../images/lite-seperator.png);*/
}

.text-uppercase {
    text-transform: uppercase;
}