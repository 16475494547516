@import '../../../assets/scss/styles/media-queries.scss';


/* Bottom */

#bottom {
    background: #19171c;
    padding: 30px 0px 0px;
    font-size: 0px;


a,
li,
p {
    color: #8b8b8b;
    font-size: 15px;
}

 p {
    line-height: 1.7em;
}

.about-us-box .about-text {
    padding-right: 40px;
}

a {
    text-decoration: underline;
    outline: none;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
}

a:hover {
    color: #fff;
}

.bottom-heading {
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 22px;
    color: #fff;
    margin-top: 0px;
    margin-top: 30px;
    .bottom-heading-line {
        background: #ffc900;
        height: 2px;
        width: 80px;
        display: block;
        margin-top: 15px;
    }
}


.services-list li {
    margin-bottom: 15px;
}



.get-intouch-box{
.media {
    margin-top: 20px;
    .media-body {
        font-size: 0px;
        p {
            margin-top: -4px;
            margin-bottom: 0px;
        }
        span{
            float: left;
            width: 40px;
            a{
                color: #ffffff;
                font-size: 22px;
            }
        }
    }
}

i.fa {
    font-size: 22px;
    color: #8b8b8b;
}
} 


.copyright{
    background: #ffc900;
    padding: 15px 0px;
    margin-top: 60px;
    color: #fff;
    display: inline-block;
    width: 100%;
p {
    margin-bottom: 0px;
    color: #fff;
}

 a {
    color: #fff;
    margin-left: 8px;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    &:hover {
        color: #19171C;
    }
}

.copyright-text {
    text-align: center;
    padding-right: 0px;
}
}

}

/* Bottom */